@tailwind base;
@tailwind components;
@tailwind utilities;

.loading-indicator:before {
  content: "";
  background: #00000080;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
}

.loading-indicator:after {
  content: " ";
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Center the spinner */
  z-index: 10010;
  color: white;
  text-align: center;
  font-weight: bold;
  font-size: 1.2rem;
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #0474bf; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

.custom-select {
  position: relative;
}
.custom-select .custom-select-list {
  position: absolute;
  background-color: white;
  color: gray;
  min-width: 100%;
  width: fit-content;
  z-index: 100;
  height: 200px;
  overflow-y: auto;
}
.custom-select .custom-select-list li {
  padding: 8px;
}
.custom-select .custom-select-list li:hover {
  background-color: rgb(243, 237, 237);
}

.custom-select-value {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  min-height: 40px;
  background-color: white;
  width: 100%;
  padding: 4px;
  border-radius: 3px;
}
.custom-select-value input {
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0px 7px;
}

.custom-select-value ::placeholder {
  color: #8f9290;
}

.custom-select-value ul {
  display: flex;
  gap: 5px;
}

.custom-select-value ul li {
  padding: 2px 4px;
  background-color: gray;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
}

.custom-select-value ul li img {
  height: 12px;
  width: 12px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
